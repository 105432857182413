@import "../../data/styles.css";

.read-article-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.read-article-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.read-article-container {
	display: flex;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.read-article-back {
	padding-top: 68px;
	padding-right: 50px;
	width: 40px;
	height: 40px;
}

.read-article-back-button {
	width: 50px;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.read-article-wrapper {
	width: 80%;
	padding-top: 30px;
}

.read-article-date-container {
	border-left: 2px solid var(--quaternary-color);
	color: var(--tertiary-color);
	font-size: 16 px;
	align-items: center;
	height: 22px;
}

.read-article-date {
	font-family: var(--primary-font);
	padding-left: 15px;
}

.read-article-title {
	padding-top: 50px;
	padding-bottom: 50px;
	width: 100% !important;
}

.read-article-report-link {
	opacity: 0.8;
	display: flex;
	justify-content: flex-end;
	text-decoration: none;
	color: var(--link-color);
	margin-bottom: 16px;
}

.read-article-report-link:hover {
	opacity: 1;
}

.read-article-body {
	padding-bottom: 50px;
	font-size: 16px;
	line-height: 30px;
	font-family: system-ui;
	color: #52525b;
}

.download-icon {
	margin-left: 12px;
	margin-right: 12px;
}
