@import "../../../data/styles.css";

.card {
	mix-blend-mode: normal;
	border-radius: 20px;
	outline: 2px solid #f4f4f5;
}

.card-container {
	padding: 30px;
	padding-bottom: 5px;
}

.card-header {
	display: flex;
	align-items: center;
}

.card-icon {
	font-size: 15px;
	color: var(--tertiary-color);
}

.card-title {
	color: var(--secondary-color);
	font-size: 16px;
	padding-left: 20px;
	font-weight: 650;
}

.card-body {
	padding-top: 40px;
	font-size: 15px;
}

@media screen and (max-width: 500px) {
	
	.card-container {
		padding: 30px 5px 30px 30px;
	}

	.card-body {
		padding-top: 10px;
		font-size: 15px;
	}

}